import { openHowGiftlyWorksModal } from '@consumer/services/modals'
import type GiftTemplate from '@consumer/types/GiftTemplate'

declare global {
  interface Window {
    currentGift: GiftTemplate
  }
}

function onExactClick (selector: string, fn: (el: HTMLElement) => void) {
  document.querySelectorAll<HTMLElement>(selector).forEach((el) => {
    el.addEventListener('click', (event) => {
      if (event.metaKey || event.shiftKey || event.ctrlKey || event.altKey) return
      event.preventDefault()
      fn(el)
    })
  })
}

export function setupHowItWorks () {
  onExactClick('.how-it-works-modal-link', () => {
    openHowGiftlyWorksModal()
  })

  onExactClick('.how-it-works-demo-modal-link', () => {
    if (window.currentGift && !window.currentGift.greeting) {
      window.currentGift.greeting = {
        message: 'Thank you for everything! I hope you have a great day. Looking forward to seeing you soon.',
        from: 'Me',
        to: 'You',
        previewImageUrl: '',
        thumbnailImageUrl: '',
      }
    }

    openHowGiftlyWorksModal({ initialStep: 'preview-landing', gift: window.currentGift })
  })
}
